import {useEffect, useState} from 'react';
import { createRoot } from 'react-dom/client';
import { Amplify } from 'aws-amplify';
import {
  Alert,
  Authenticator,
  Loader,
  Message,
} from '@aws-amplify/ui-react';
import { fetchUserAttributes } from 'aws-amplify/auth';
import { RedocStandalone, SideNavStyleEnum } from 'redoc';
import '@aws-amplify/ui-react/styles.css';
import './index.css'

const cognitoClientId = '18vtuf9nm8aig558g6442meb2f'
const congitoPoolId = 'us-east-1_QixjBWeYc'
Amplify.configure(
  {
    region: 'us-east-1',
    Auth: {
      Cognito: {
        userPoolId: congitoPoolId,
        userPoolClientId: cognitoClientId,
        allowGuestAccess: false,
        loginWith: {
          oauth: {
            domain: 'https://emotiv-dev.auth.us-east-1.amazoncognito.com',
            scope: ['email', 'openid'],
            redirectSignIn: ['https://docs.emotivcloud.com/auth'],
            responseType: 'code',
          },
          email: true,
        }
      }
    }
  }
);

function APIDoc() {
  return (
    <RedocStandalone
      specUrl="/openapi.json"
      options={{
        nativeScrollbars: false,
        menuToggle: true,
        hideDownloadButton: true,
        hideHostname: false,
        sideNavStyle: SideNavStyleEnum.SummaryOnly,
      }}
    />
  );
}


const UserLoggedIn = ({signOut, user}) => {
  const [attrs, setAttrs] = useState(null);
  useEffect(() => {
    async function getUserAttributes() {
      const attrs = await fetchUserAttributes()
      setAttrs(attrs)
    }
    getUserAttributes()
  }, [user])

  if (!attrs) {
    return <Loader size="large" variation='linear'/>
  }
  if (!attrs.email.endsWith('@emotiv.com')) {
    return (
      <Alert variation='error'>You are not authorized to view this page</Alert>
    )
  }
  return (
    <div>
      <Message variation='outlined' colorTheme='success' >You are logged in as {attrs.email}</Message>
      <APIDoc />
    </div>
  )
}


export default function App() {
  return (
    <Authenticator hideSignUp="true" loginMechanism='email' variation='modal'>
      {({ signOut, user }) => (
        (user ? <UserLoggedIn signOut={signOut} user={user}/> : null)
      )}
    </Authenticator>
  );
}

// Clear the existing HTML content
document.body.innerHTML = '<div class="app"></div>';
// Render your React component instead
const root = createRoot(document.getElementsByClassName('app')[0]);
root.render(<App />)
